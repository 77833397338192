import { Box, Button, ChakraProvider} from "@chakra-ui/react";
import { Flex, Spacer } from '@chakra-ui/react'
import { Text, Select } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import axios from "axios";
import { Card, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { Heading, Stack, StackDivider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
function App() {
  const [selectedProvinsi, setSelectedProvinsi] = useState(null);
  const [selectedKabupaten, setSelectedKabupaten] = useState(null);
  

  const [provinsi, setProvinsi] = useState([]);
  const [kabupaten, setKabupaten] = useState([]);
  const [ponpes, setPonpes] = useState([]);
  const tampilProvinsi = async () => {
    try{
      const response = await axios.get("https://api-pesantren-indonesia.vercel.app/provinsi.json");
      setProvinsi(response.data);
    }catch(err){
      console.log(err);
    }
  }

  const tampilKabupaten = async (idProvinsi) => {
    try{
      const response = await axios.get(`https://api-pesantren-indonesia.vercel.app/kabupaten/${idProvinsi}.json`);
      setKabupaten(response.data);
    }catch(err){
      console.log(err);
    }
  }

  const tampilPonpes = async (idKabupaten) => {
    try{
      const response = await axios.get(`https://api-pesantren-indonesia.vercel.app/pesantren/${idKabupaten}.json`);
      setPonpes(response.data);
    }catch(err){
      console.log(err);
    }
  }

  const handleProvinsiChange =  (event)  => { 
    const selectedProvinsiId = event.target.value;
    setSelectedProvinsi(selectedProvinsiId);
    tampilKabupaten(selectedProvinsiId);
  }

  const handleKabupatenChange = (event) => {
    const selectedKabupatenId = event.target.value;
    setSelectedKabupaten(selectedKabupatenId);
    tampilPonpes(selectedKabupatenId);
  }

  const getMapsUrl = (alamat) => {
    const encodedAlamat = encodeURIComponent(alamat);
    return `https://www.google.com/maps/search/?api=1&query=${encodedAlamat}`;
  };
  
  useEffect(() => {
    tampilProvinsi()
  }, [provinsi]);


  return (
    <ChakraProvider bg="green">
    <Box
        className="App"
        minH="100vh"
        bgGradient="linear(to-b, green.200, green.400, green.600)">
    <Box borderRadius={10}  as="header" background={"white"} textColor="green.400" className="header" textAlign={"center"} fontWeight={"bold"} fontSize={28} padding={5}>
          <h1>CARI PONPES</h1> 
    </Box>
    <Box w={{ base: "90%", md: "75%", lg: "60%" }}  marginX={"auto"} marginY={5} >
        <Flex gap={4}>
            <Box flex="1">
            <Select size="md" bg={"white"} shadow={"initial"} placeholder='Pilih Provinsi' onChange={handleProvinsiChange}>
            {
              provinsi.length > 0 ? (
                provinsi.map(item => (
                  <option value={item.id} key={item.id} size='xs'>
                    {item.nama}
                  </option>
                ))
              ) : (
                <option>Belum Ada Data</option>
              )
            }
</Select>
            </Box>
            <Box flex="1">
            <Select placeholder='Pilih Kabupaten' bg={"white"} shadow={"initial"} onChange={handleKabupatenChange}>
            {
              kabupaten.length > 0 ? (
                kabupaten.map(item => (
                  <option value={item.id} key={item.id}>
                    {item.nama}
                  </option>
                ))
              ) : (
                <option>Belum Menampilkan  Data</option>
              )
            }
</Select>
            </Box>
        </Flex>
        <Box marginY={5}>
          {
            ponpes.length > 0 ? (
              <Grid templateColumns={{
        base: "repeat(1, 1fr)", // 2 columns on small screens
        md: "repeat(2, 1fr)",   // 3 columns on medium screens
        lg: "repeat(4, 1fr)",   // 4 columns on large screens
      }} gap={6}>
                {
              ponpes.map(item => (
                <GridItem my={2} w='100%'>
                    <Card >
  <CardHeader>
    <Heading size='md'>{item.nama}</Heading>
  </CardHeader>
  <CardBody>
    <Stack divider={<StackDivider />} spacing='4'>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
         NSPP
        </Heading>
        <Text pt='2' fontSize='sm'>
          {item.nspp}
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Alamat
        </Heading>
        <Text pt='2' fontSize='sm'>
          {item.alamat} {item.kab_kota.nama} , {item.provinsi.nama}
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Kyai
        </Heading>
        <Text pt='2' fontSize='sm'>
          {
            item.kyai !== "" ? item.kyai : "-"
          }
        </Text>
      </Box>
      <Box>
       
       <Button onClick={() => window.open(getMapsUrl(`${item.nama} ${item.alamat} ${item.kab_kota.nama} , ${item.provinsi.nama}`), "_blank")}>
  Lokasi
</Button>
      </Box>
    </Stack>
  </CardBody>
</Card>
                </GridItem> 
              ))
            }
          </Grid>
            ) : (
              <Box fontSize={20} fontWeight={"bold"} textColor={"white"} textShadow={"inherit"} textAlign={"center"}>
                  <p>Belum Menampilkan Data</p>
              </Box>
            )
          }
        </Box>
    </Box>
  </Box>
    </ChakraProvider>
  );
}

export default App;
